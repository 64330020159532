export const palette = {
  primary: {
    // light: will be calculated from palette.primary.main,
    main: '#7284A8',
    // dark: will be calculated from palette.primary.main,
    // contrastText: will be calculated to contrast with palette.primary.main
  },
  secondary: {
    light: '#BA1200',
    main: '#BA1200',
    // dark: will be calculated from palette.secondary.main,
    contrastText: '#F4F4F9',
  }
}
