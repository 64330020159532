import React from 'react';
import './coming-soon.css';
import { makeStyles, hexToRgb } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import product from '../../images/pet-pilot-hero.png'

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
}));

function ComingSoon() {
  const classes = useStyles();
  return (
    <div className="comingSoon">
      <p>New Pet-Pilot Site Coming Soon!</p>
      <img className="productImage" src={product} alt="Failed to load" data-reactid=".0.0"></img>
    </div>
  );
}

export default ComingSoon;
