import React from 'react';
import logo from './logo.svg';
import './App.css';
import Homepage from './components/homepage/'
import Header from './components/header';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { palette } from './palette';
import ComingSoon from './components/coming-soon';

const theme = createMuiTheme({
  palette,
});

function App() {
  return (
    // <ThemeProvider theme={theme} className="App">
    //   <Header></Header>
    //   <Homepage></Homepage>
    // </ThemeProvider>
    <ComingSoon></ComingSoon>
  );
}

export default App;
